.email-template {
    font-family: Arial, sans-serif;
    margin: 0 auto;
    max-width: 600px;
    padding: 20px;
}

.email-table {
    width: 100%;
}

.email-heading {
    color: #333;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.email-content {
    color: #555;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 15px;
}

.email-content:last-child {
    margin-bottom: 0;
}
